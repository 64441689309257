<template>
  <div>
    <Header />
    <Table :columns="columns" :data="data" />
    <Select :visible="false" />
  </div>
</template>

<script>
import Table from '../../components/table';
import Header from '../../components/header';
import Select from '../../components/select/reports/terminal';
export default {
  components: {
    Table,
    Header,
    Select,
  },
  data() {
    return {
      columns: [
        { name: '工艺', props: 'name' },
        { name: '配比', props: 'matching' },
        { name: '本年库存', props: 'thisYearStock' },
        { name: '竞品库存', props: 'competitorStock' },
      ],
      data: [
        { name: '名字', matching: 30, thisYearStock: 1234, competitorStock: 2133 },
        { name: '名字', matching: 30, thisYearStock: 1234, competitorStock: 2133 },
        { name: '名字', matching: 30, thisYearStock: 1234, competitorStock: 2133 },
        { name: '名字', matching: 30, thisYearStock: 1234, competitorStock: 2133 }
      ]
    }
  }
}
</script>