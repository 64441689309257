<template>
  <div class="common-table">
    <div class="theader">
      <div class="th" v-for="col in columns" :key="col.props">
        {{col.name}}
      </div>
    </div>
    <div class="tbody" :style="{'margin-top': layer ? '12px' : '0'}">
      <div class="tr" v-for="(row, idx) in data" :key="idx">
        <div class="td" v-for="col in columns" :key="col.props + idx">
          {{row[col.props]}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => [],
    },
    layer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.common-table {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2C2C2C;
  .theader {
    background-color: #fff;
    display: flex;
    align-items: center;
    min-height: 44px;
    line-height: 20px;
    // line-height: 44px;
    .th {
      flex: 1;
    }
  }
  .tr {
    background-color: #fff;
    display: flex;
    align-items: center;
    line-height: 32px;
    .td {
      flex: 1;
    }
  }
}
</style>